import MuiButton, { ButtonProps as MuiButtonProps } from "@mui/material/Button"
import { CSS } from "@stitches/react"
import React, { ReactElement } from "react"
import { styled } from "@/styles/stitches.config"
import { useAuth } from "~/hooks/use-auth"
import { useResource } from "~/hooks/use-resource"
import { TrackingApi } from "~/services/aoeu"
import { TRACKING_EVENT_TYPES, TrackingEventType } from "~/services/aoeu/models"
import logger from "~/util/logger"

type ButtonColor =
  | "primary"
  | "secondary"
  | "error"
  | "inherit"
  | "aoeu"
  | "white"
type ButtonVariant = "contained" | "outlined" | "text"
export type ButtonProps<C extends React.ElementType> = Omit<
  MuiButtonProps,
  "color" | "variant" | "disabled" | "component"
> & {
  color?: ButtonColor
  variant?: ButtonVariant
  round?: boolean
  disabled?: boolean
  as?: C
} & PickOptional<TrackingProps, "eventType"> &
  React.ComponentPropsWithoutRef<C>

export type TrackingProps = {
  // use null to explicitly opt out of event tracking
  eventTitle: string | null | undefined
  eventType: TrackingEventType
}

type ButtonStyleProps = {
  color: string
  backgroundColor?: string
  hoverColor: string
  activeColor: string
  focusColor: string
}

export function Button<C extends React.ElementType>({
  color = "primary",
  variant = "contained",
  round = false,
  disabled = false,
  as,
  onClick,
  eventTitle,
  eventType = TRACKING_EVENT_TYPES.CLICK,
  ...props
}: ButtonProps<C>): ReactElement {
  const { trackingApi } = useResource()
  const auth = useAuth()
  const _onClick: typeof onClick = (...args) => {
    trackClick(trackingApi, auth, eventTitle, eventType)
    return onClick && onClick(...args)
  }

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore this is an obtuse typescript error that I don't understand, you may fare better than me but I wouldn't waste your time
    <StyledButton
      color={"primary"}
      variant={variant}
      mycolor={color}
      myvariant={variant}
      round={round}
      disabled={disabled}
      component={as}
      onClick={_onClick}
      {...props}
    />
  )
}

export function trackClick(
  api: TrackingApi,
  auth: ReturnType<typeof useAuth>,
  eventTitle: TrackingProps["eventTitle"],
  eventType: TrackingProps["eventType"],
): void {
  if (auth.userData?.id && auth.authenticated && eventTitle !== null) {
    const event = {
      event_description: `${document.title} - ${eventTitle}`,
      event_page_id: window.location.href,
      event_type: eventType,
      event_time: new Date().toISOString(),
      user_id: auth.userData.id,
    }
    const track = () => {
      api.trackEvent(event).catch(e => {
        logger
          .withScope({ tags: { caller: "button.customTracking", ...event } })
          .error(e)
      })
    }
    // If a link triggers a browser native download event at the same time we send an XHR
    // request, the XHR request is cancelled; we delay sending the request to mitigate this problem.
    if (eventType === TRACKING_EVENT_TYPES.DOWNLOAD) {
      window._aoeuDownloadEvents = window._aoeuDownloadEvents ?? []
      window._aoeuDownloadEvents.push(track)
      setTimeout(() => {
        const track_ = window._aoeuDownloadEvents?.pop()
        track_ && track_()
      }, 250)
    } else {
      track()
    }
  }
}

const StyledButton = styled(MuiButton, {
  $$containedBorderPadding: "0.25rem",
  $$outlinedBorderPadding: "0.32rem",
  $$halfBorderPadding: "calc($$containedBorderPadding / 2)",
  variants: {
    mycolor: {
      primary: {
        /* see compound variants for styles*/
      },
      secondary: {
        /* see compound variants for styles*/
      },
      error: {
        /* see compound variants for styles*/
      },
      inherit: {
        /* see compound variants for styles*/
      },
      aoeu: {
        /* see compound variants for styles*/
      },
    },
    myvariant: {
      outlined: {},
      contained: {},
    },
    round: {
      true: {
        "&.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary,&.MuiButton-outlined":
          {
            "borderRadius": "1.5rem",
            "&:focus": {
              // extra border on focus
              "&::before": {
                borderRadius: "1.5rem",
              },
            },
          },
        "&.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary": {
          "&::after": {
            borderRadius: "1.5rem",
          },
          "&:focus": {
            "&::before": {
              borderRadius: "1.5rem",
            },
          },
        },
      },
    },
  },
  compoundVariants: [
    {
      mycolor: "primary",
      myvariant: "contained",
      css: getButtonStyles({ color: "primary", variant: "contained" }),
    },
    {
      mycolor: "secondary",
      myvariant: "contained",
      css: getButtonStyles({ color: "secondary", variant: "contained" }),
    },
    {
      mycolor: "error",
      myvariant: "contained",
      css: getButtonStyles({ color: "error", variant: "contained" }),
    },
    {
      mycolor: "inherit",
      myvariant: "contained",
      css: getButtonStyles({ color: "inherit", variant: "contained" }),
    },
    {
      mycolor: "aoeu",
      myvariant: "contained",
      css: getButtonStyles({ color: "aoeu", variant: "contained" }),
    },

    {
      mycolor: "primary",
      myvariant: "outlined",
      css: getButtonStyles({ color: "primary", variant: "outlined" }),
    },
    {
      mycolor: "secondary",
      myvariant: "outlined",
      css: getButtonStyles({ color: "secondary", variant: "outlined" }),
    },
    {
      mycolor: "error",
      myvariant: "outlined",
      css: getButtonStyles({ color: "error", variant: "outlined" }),
    },
    {
      mycolor: "inherit",
      myvariant: "outlined",
      css: getButtonStyles({ color: "inherit", variant: "outlined" }),
    },
    {
      mycolor: "aoeu",
      myvariant: "outlined",
      css: getButtonStyles({ color: "aoeu", variant: "outlined" }),
    },
    {
      mycolor: "white",
      myvariant: "text",
      css: getButtonStyles({ color: "white", variant: "text" }),
    },
    {
      mycolor: "white",
      myvariant: "contained",
      css: getButtonStyles({ color: "white", variant: "contained" }),
    },
    {
      mycolor: "white",
      myvariant: "outlined",
      css: getButtonStyles({ color: "white", variant: "outlined" }),
    },
  ],
})

function getButtonStyles({
  color = "primary",
  variant = "contained",
}: {
  color: ButtonColor
  variant: ButtonVariant
}): CSS {
  const dictionaryContained: Record<typeof color, ButtonStyleProps> = {
    primary: {
      color: "$whiteSmoke",
      backgroundColor: "$aoeuBlue2",
      hoverColor: "$blueFlame",
      activeColor: "$aoeuBlue2",
      focusColor: "$aoeuBlue2",
    },
    secondary: {
      color: "$whiteSmoke",
      backgroundColor: "$gunmetalGray",
      hoverColor: "$darkGray",
      activeColor: "$gunmetalGray",
      focusColor: "$darkGray",
    },
    error: {
      color: "$whiteSmoke",
      backgroundColor: "$shiraz",
      hoverColor: "$red",
      activeColor: "$shiraz",
      focusColor: "$red",
    },
    inherit: {
      color: "$whiteSmoke",
      backgroundColor: "$black",
      hoverColor: "$gray800",
      activeColor: "$black",
      focusColor: "$gray800",
    },
    aoeu: {
      color: "$whiteSmoke",
      backgroundColor: "$aoeuBlue",
      hoverColor: "$aoeuBlue3",
      activeColor: "$aoeuBlue",
      focusColor: "$aoeuBlue3",
    },
    white: {
      color: "$aoeuBlue2",
      backgroundColor: "$white",
      hoverColor: "$whiteSmoke",
      activeColor: "",
      focusColor: "",
    },
  }

  const dictionaryOutlined: Record<typeof color, ButtonStyleProps> = {
    primary: {
      color: "$aoeuBlue2",
      hoverColor: "$blueFlame",
      activeColor: "$aoeuBlue2",
      focusColor: "$blueFlame",
    },
    secondary: {
      color: "$gunmetalGray",
      hoverColor: "$darkGray",
      activeColor: "$gunmetalGray",
      focusColor: "$darkGray",
    },
    error: {
      color: "$shiraz",
      hoverColor: "$shiraz",
      activeColor: "$shiraz",
      focusColor: "$shiraz",
    },
    inherit: {
      color: "$black",
      hoverColor: "$gray800",
      activeColor: "$black",
      focusColor: "$gray800",
    },
    aoeu: {
      color: "$aoeuBlue",
      hoverColor: "$aoeuBlue3",
      activeColor: "$aoeuBlue",
      focusColor: "$aoeuBlue3",
    },
    white: {
      color: "$white",
      hoverColor: "$whiteSmoke",
      activeColor: "$white",
      focusColor: "$whiteSmoke",
    },
  }

  const containedStyle = dictionaryContained[color]
  const outlinedStyle = dictionaryOutlined[color]

  const css: Record<typeof variant, CSS> = {
    contained: {
      "&.MuiButton-containedPrimary:not(.Mui-disabled)": {
        "color": `${containedStyle.color}`,
        "backgroundColor": `${containedStyle.backgroundColor}`,
        "&:hover,&:focus": {
          backgroundColor: `${containedStyle.hoverColor}`,
        },
        "&:focus": {
          // extra border on focus
          "&::before": {
            content: '""',
            border: `solid 2px ${containedStyle.focusColor}`,
            borderRadius: "5px",
            width: "calc(100% + $$containedBorderPadding)",
            height: "calc(100% + $$containedBorderPadding * 1.2)",
            position: "absolute",
            top: "-$$containedBorderPadding",
            left: "-$$containedBorderPadding",
          },
        },
        "&:active": {
          backgroundColor: `${containedStyle.activeColor}`,
        },
      },
    },
    outlined: {
      "&.MuiButton-outlined": {
        // give the border some heft
        "&::after": {
          borderStyle: "solid",
          borderWidth: "2px",
          borderRadius: "3px",
          content: '""',
          width: "100%",
          height: "100%",
          position: "absolute",
          top: "-2px",
          left: "-2px",
        },
      },
      "&.MuiButton-outlinedPrimary:not(.Mui-disabled)": {
        "color": `${outlinedStyle.color}`,
        "borderColor": `${outlinedStyle.color}`,
        "&::after": {
          borderColor: `${outlinedStyle.color}`,
        },
        "&:hover": {
          "color": `${outlinedStyle.hoverColor}`,
          "borderColor": `${outlinedStyle.hoverColor}`,
          "&::after": {
            borderColor: `${outlinedStyle.hoverColor}`,
          },
        },
        "&:focus": {
          // extra border on focus
          "color": `${outlinedStyle.focusColor}`,
          "borderColor": `${outlinedStyle.focusColor}`,
          "&::before": {
            content: '""',
            border: `solid 2px ${outlinedStyle.focusColor}`,
            borderRadius: "5px",
            width: "calc(100% + $$outlinedBorderPadding * 1.2)",
            height: "calc(100% + $$outlinedBorderPadding * 1.2)",
            position: "absolute",
            top: "-$$outlinedBorderPadding",
            left: "-$$outlinedBorderPadding",
          },
        },
        "&:active": {
          "color": `${outlinedStyle.activeColor}`,
          "borderColor": `${outlinedStyle.activeColor}`,
          "&::before": {
            border: `solid 2px ${outlinedStyle.activeColor}`,
          },
        },
      },
    },
    text: {
      "&.MuiButton-text": {
        color: `${outlinedStyle.color}`,
      },
      "&:hover": {
        color: `${outlinedStyle.hoverColor}`,
      },
    },
  }

  return css[variant]
}
