import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import AutorenewIcon from "@mui/icons-material/Autorenew"
import BusinessIcon from "@mui/icons-material/Business"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import ClassIcon from "@mui/icons-material/Class"
import CloseIcon from "@mui/icons-material/Close"
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact"
import HelpIcon from "@mui/icons-material/Help"
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined"
import LoginIcon from "@mui/icons-material/Login"
import LogoutIcon from "@mui/icons-material/Logout"
import MenuIcon from "@mui/icons-material/Menu"
import PeopleIcon from "@mui/icons-material/People"
import SchoolIcon from "@mui/icons-material/School"
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined"
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium"
import MuiAppBar from "@mui/material/AppBar"
import Divider from "@mui/material/Divider"
import MuiDrawer from "@mui/material/Drawer"
import IconButton from "@mui/material/IconButton"
import List from "@mui/material/List"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { useTheme } from "@mui/material/styles"
import Toolbar from "@mui/material/Toolbar"
import clsx from "clsx"
import useSnacks from "flex/hooks/useSnacks"
import { useTranslations } from "next-intl"
import * as React from "react"
import { ElementType, ReactNode } from "react"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Headroom from "react-headroom"
import { styled } from "@/styles/stitches.config"
import { adminUrls, externalUrls, pages } from "@/urls"
import {
  getUserDisplayName,
  getUserEntitlementStatus,
  isActive,
} from "admin/utils"
import { Avatar } from "~/components/avatar"
import { Link, NextWrappedLink } from "~/components/link"
import { SvgIcon } from "~/components/svg-icons"
import { Logo } from "~/components/svg-logos"
import { FEATURES, ROLES, STUDENT_TYPE } from "~/enums"
import { asHTTPError } from "~/errors/http-request-error"
import { useAuth } from "~/hooks/use-auth"
import { usePreferences } from "~/hooks/use-preferences"
import { useResource } from "~/hooks/use-resource"
import { useRouter } from "~/hooks/use-router"
import { useIsBrowser } from "~/hooks/useIsBrowser"
import { rstrip } from "~/util"
import logger from "~/util/logger"

export type HeaderProps = {
  children?: ReactNode
  className?: string
}

const FLEX_PATTERN = /^\/?flex\/?/i
const PRO_PATTERN = /^\/?pro\/?/i
const MARKETPLACE_PATTERN = /^\/purchase\//i
const ADMIN_PATTERN = /^\/?admin\//i
export const BASE_NO_SIDEBAR_PATHS = new Set([
  pages.home,
  pages.user.login,
  pages.user.logout,
  pages.user.social,
  pages.user.create,
  pages.user.forgotPassword,
  pages.user.resetPassword,
  pages.user.verifyEmail,
  pages.user.resendVerifyEmail,
  pages.user.unverifiedEmail,
  pages.termsAndConditions,
  pages.flex.search,
  pages.pro.search,
])

export const Header = styled(
  ({ children, className }: HeaderProps) => {
    const theme = useTheme()
    const { api } = useResource()
    const { showError } = useSnacks()
    const router = useRouter()
    const {
      authenticated,
      isDistrictAdmin,
      user: {
        data: { firstName, lastName, studentType, id: userId },
        roles = [],
      },
      entitlements,
      features,
    } = useAuth()
    const isCurriculumsEnabled = features.has(FEATURES.ENABLE_FLEX_CURRICULUMS)

    const [{ sidebarOpen = false }, updatePreferences] = usePreferences()
    const [profileMenuAnchor, setProfileMenuAnchor] =
      React.useState<null | HTMLElement>()
    const hasLoadedClientSide = useIsBrowser()

    // localhost is used to provide an origin for the URL constructor, but is never used,
    // so this is production safe. Using a hard-coded origin also means this works in SSR.
    const currentPath = new URL(router.asPath, "http://localhost").pathname
    const { pro, flex } = getUserEntitlementStatus(entitlements, isActive)

    // it does not make sense to show the left sidebar on all routes.
    const shouldShowSidebar = getShouldShowSidebar(currentPath, authenticated)

    const shownAppIcons = FLEX_PATTERN.test(currentPath)
      ? "flex"
      : PRO_PATTERN.test(currentPath)
      ? "pro"
      : ADMIN_PATTERN.test(currentPath) && !isDistrictAdmin
      ? "admin"
      : null
    const showAdminPortal = roles.includes(ROLES.ADMIN_PORTAL_ACCESS)
    const name = getUserDisplayName({ firstName, lastName })
    const t = useTranslations("components.newHeader")

    const higherEdRedirect = () => {
      api
        .getProHigherEdRedirect(userId!)
        .then(({ data: { Link: link } }) => {
          window.open(link!, "_blank")?.focus()
        })
        .catch(e => {
          const error = asHTTPError(e)
          showError(error.message)
          showError(t("sidebar.error.sso"))
          logger
            .withScope({
              tags: {
                caller: "getProHigherEdRedirect",
                url: window.location.href,
                userId: userId!,
              },
            })
            .error(e)
        })
    }

    const proInProgressRedirect = () => {
      api
        .getProInProgressRedirect(userId!)
        .then(({ data: { Link: link } }) => {
          window.open(link!, "_blank")?.focus()
        })
        .catch(e => {
          const error = asHTTPError(e)
          showError(error.message)
          showError(t("sidebar.error.sso"))
          logger
            .withScope({
              tags: {
                caller: "getProInProgressRedirect",
                url: window.location.href,
                userId: userId!,
              },
            })
            .error(e)
        })
    }

    const proCertificatesRedirect = () => {
      api
        .getProCertificatesRedirect(userId!)
        .then(({ data: { Link: link } }) => {
          window.open(link!, "_blank")?.focus()
        })
        .catch(e => {
          const error = asHTTPError(e)
          showError(error.message)
          showError(t("sidebar.error.sso"))
          logger
            .withScope({
              tags: {
                caller: "getProCertificatesRedirect",
                url: window.location.href,
                userId: userId!,
              },
            })
            .error(e)
        })
    }

    const dropdownItems = {
      login: (
        <MenuItem
          key={pages.user.login}
          component={NextWrappedLink}
          href={pages.user.login}
          decorated={false}
          outlined={false}>
          <ListItemIcon>
            <LoginIcon fontSize="small" />
          </ListItemIcon>
          {t("profileMenu.login")}
        </MenuItem>
      ),
      logout: (
        <MenuItem
          key={pages.user.logout}
          component={NextWrappedLink}
          href={pages.user.logout}
          decorated={false}
          outlined={false}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          {t("profileMenu.logout")}
        </MenuItem>
      ),
      profile: (
        <MenuItem
          key={pages.user.profile}
          component={NextWrappedLink}
          href={pages.user.profile}
          decorated={false}
          outlined={false}>
          <ListItemIcon>
            <AccountCircleIcon fontSize="small" />
          </ListItemIcon>
          {t("profileMenu.profile")}
        </MenuItem>
      ),
      myCourses: (
        <MenuItem key="my-courses" onClick={higherEdRedirect}>
          <ListItemIcon>
            <ClassIcon fontSize="small" />
          </ListItemIcon>
          {t("profileMenu.myCourses")}
        </MenuItem>
      ),
      studentPortal: (
        <MenuItem
          key={externalUrls.student.campusCafeSso}
          component={Link}
          href={externalUrls.student.campusCafeSso}
          target="_blank"
          decorated={false}
          outlined={false}>
          <ListItemIcon>
            <SchoolIcon fontSize="small" />
          </ListItemIcon>
          {t("profileMenu.studentPortal")}
        </MenuItem>
      ),
      now: (
        <MenuItem
          key={pages.user.subscriptions}
          component={NextWrappedLink}
          href={`${pages.user.subscriptions}#event-records`}
          decorated={false}
          outlined={false}>
          <ListItemIcon sx={{ paddingLeft: ".125rem" }}>
            <Logo symbolId="now-conference" size="medium" />
          </ListItemIcon>
          {t("profileMenu.now")}
        </MenuItem>
      ),
      pro: (
        <MenuItem key="pro-certificates" onClick={proCertificatesRedirect}>
          <ListItemIcon sx={{ paddingLeft: ".125rem" }}>
            <Logo symbolId="pro-learning" size="medium" />
          </ListItemIcon>
          {t("profileMenu.pro")}
        </MenuItem>
      ),
      help: (
        <MenuItem
          key={externalUrls.help}
          component={Link}
          href={externalUrls.help}
          target="_blank"
          decorated={false}
          outlined={false}>
          <ListItemIcon>
            <HelpIcon fontSize="small" />
          </ListItemIcon>
          {t("profileMenu.help")}
        </MenuItem>
      ),
      contact: (
        <MenuItem
          key={externalUrls.contact}
          component={Link}
          href={externalUrls.contact}
          target="_blank"
          decorated={false}
          outlined={false}>
          <ListItemIcon>
            <ConnectWithoutContactIcon fontSize="small" />
          </ListItemIcon>
          {t("profileMenu.contact")}
        </MenuItem>
      ),
      admin: (
        <MenuItem
          key={adminUrls.home}
          component={NextWrappedLink}
          href={adminUrls.home}
          decorated={false}
          outlined={false}>
          <ListItemIcon>
            <SupervisorAccountOutlinedIcon fontSize="small" />
          </ListItemIcon>
          {t("sidebar.admin")}
        </MenuItem>
      ),
    }

    let dropdownMenu: ReactNode[] = []
    if (authenticated) {
      dropdownMenu = dropdownMenu.concat([
        dropdownItems.profile,
        <Divider key="d1" />,
        dropdownItems.now,
        dropdownItems.pro,
        <Divider key="d2" />,
        dropdownItems.help,
        dropdownItems.contact,
        <Divider key="d3" />,
        dropdownItems.logout,
      ])
      // for students, we add items right under profile
      if (studentType === STUDENT_TYPE.MASTERS) {
        dropdownMenu.splice(
          1,
          0,
          dropdownItems.myCourses,
          dropdownItems.studentPortal,
        )
      } else if (studentType === STUDENT_TYPE.COURSE_ONLY) {
        dropdownMenu.splice(1, 0, dropdownItems.myCourses)
      }
      // for admin users, we add a link to the admin portal right above the logout option
      if (showAdminPortal) {
        dropdownMenu.splice(-1, 0, dropdownItems.admin, <Divider key="d0" />)
      }
    } else {
      dropdownMenu = dropdownMenu.concat([
        dropdownItems.help,
        dropdownItems.contact,
        <Divider key="d4" />,
        dropdownItems.login,
      ])
    }

    return (
      <div className={clsx(className, sidebarOpen && "open")}>
        <Headroom>
          {/* For reasons I do not yet understand, React-DND stops being able to
           locate elements on the screen unless a Headroom is somewhere in the layout. */}
          <header />
        </Headroom>
        <MuiAppBar
          className={clsx(
            "app-bar",
            sidebarOpen && shouldShowSidebar && "open",
          )}
          component="header">
          <Toolbar className="toolbar">
            {shouldShowSidebar && (
              <>
                <IconButton
                  className={clsx("menu-button", sidebarOpen && "hidden")}
                  color="inherit"
                  aria-label="open drawer"
                  onClick={() => updatePreferences({ sidebarOpen: true })}
                  edge="start">
                  <MenuIcon />
                </IconButton>
                <IconButton
                  className={clsx("close-button", !sidebarOpen && "hidden")}
                  color="inherit"
                  aria-label="close drawer"
                  onClick={() => updatePreferences({ sidebarOpen: false })}
                  edge="start">
                  <CloseIcon />
                </IconButton>
              </>
            )}
            <div
              className={clsx(
                "logo-container",
                shouldShowSidebar && "logo-container-with-sidebar",
              )}>
              <NextWrappedLink
                href={externalUrls.marketing.home}
                decorated={false}
                outlined={false}
                rel="noopener noreferrer"
                target="_blank">
                <Logo symbolId="aoeu-full-logo" className="logo" />
              </NextWrappedLink>
              <IconButton onClick={e => setProfileMenuAnchor(e.currentTarget)}>
                <Avatar name={name} size={48} />
              </IconButton>
              <Menu
                sx={{ zIndex: 1500 }}
                anchorEl={profileMenuAnchor}
                open={!!profileMenuAnchor}
                onClick={() => setProfileMenuAnchor(null)}
                onClose={() => setProfileMenuAnchor(null)}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    "overflow": "visible",
                    "filter": "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    "mt": 1,
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 25,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                {dropdownMenu}
              </Menu>
            </div>
          </Toolbar>
        </MuiAppBar>
        {shouldShowSidebar && (
          <MuiDrawer
            data-testid="sidebar"
            variant="permanent"
            open={sidebarOpen}
            className={clsx(
              "drawer",
              shownAppIcons,
              showAdminPortal && "admin-user",
              sidebarOpen ? "open" : "closed",
              hasLoadedClientSide && "loaded",
            )}>
            <div className="header">
              <IconButton
                onClick={() => updatePreferences({ sidebarOpen: false })}>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </div>
            <Divider />
            <List className="list">
              <CustomListItem
                text="My AOEU"
                href={pages.home}
                currentPath={currentPath}
                selectedPattern={/^\/?$/}
                open={sidebarOpen}>
                <HomeOutlinedIcon />
              </CustomListItem>
              <CustomListItem
                open={sidebarOpen}
                text={t("sidebar.FLEX")}
                href={flex ? pages.flex.home : pages.purchase.flex.home}
                selected={currentPath === pages.flex.home}
                className="product-logo">
                <Logo symbolId="flex-curriculum" size="medium" />
              </CustomListItem>
              <CustomListItem
                open={sidebarOpen}
                text={t("sidebar.PRO")}
                href={pro ? pages.pro.home : pages.purchase.pro.home}
                selected={currentPath === pages.pro.home}
                className="product-logo">
                <Logo symbolId="pro-learning" size="medium" />
              </CustomListItem>
            </List>
            <Divider className="flex-only" />
            <List className="flex-only list">
              <CustomListItem
                open={sidebarOpen}
                text={t("sidebar.implementation")}
                href={pages.flex.implementation}
                currentPath={currentPath}>
                <SvgIcon symbolId="implementation" size="large" />
              </CustomListItem>
              {isCurriculumsEnabled && (
                <CustomListItem
                  open={sidebarOpen}
                  text="Curricula"
                  href={pages.flex.curricula.index}
                  currentPath={currentPath}>
                  <SvgIcon symbolId="curricula" size="large" />
                </CustomListItem>
              )}
              <CustomListItem
                open={sidebarOpen}
                text={t("sidebar.collections")}
                href={pages.flex.collections.index}
                currentPath={currentPath}>
                <SvgIcon symbolId="collections" size="large" />
              </CustomListItem>
              <CustomListItem
                open={sidebarOpen}
                text={t("sidebar.lessons")}
                href={pages.flex.lessonPlans.index}
                currentPath={currentPath}>
                <SvgIcon symbolId="lessons" size="large" />
              </CustomListItem>
              <CustomListItem
                open={sidebarOpen}
                text={t("sidebar.resources")}
                href={pages.flex.resources.index}
                currentPath={currentPath}>
                <SvgIcon symbolId="resources" size="large" />
              </CustomListItem>
              <CustomListItem
                open={sidebarOpen}
                text={t("sidebar.videos")}
                href={pages.flex.videos.index}
                currentPath={currentPath}>
                <SvgIcon symbolId="videos" size="large" />
              </CustomListItem>
              <CustomListItem
                open={sidebarOpen}
                text={t("sidebar.assessments")}
                href={pages.flex.assessments.index}
                currentPath={currentPath}>
                <SvgIcon symbolId="assessments" size="large" />
              </CustomListItem>
              <CustomListItem
                open={sidebarOpen}
                text={t("sidebar.myStandards")}
                href={pages.flex.myStandards}
                currentPath={currentPath}>
                <SvgIcon symbolId="myStandards" size="large" />
              </CustomListItem>
              <CustomListItem
                open={sidebarOpen}
                text={t("sidebar.myClasses")}
                href={pages.flex.myClasses.index}
                currentPath={currentPath}>
                <SvgIcon symbolId="myClasses" size="large" />
              </CustomListItem>
            </List>
            <Divider className="pro-only" />
            <List className="pro-only list">
              <CustomListItem
                open={sidebarOpen}
                text={t("sidebar.implementation")}
                href={pages.pro.implementation}
                currentPath={currentPath}>
                <SvgIcon symbolId="implementation" size="large" />
              </CustomListItem>
              <CustomListItem
                open={sidebarOpen}
                text={t("sidebar.searchProPacks")}
                href={pages.pro.packs}
                currentPath={currentPath}>
                <SvgIcon symbolId="search-icon" size="large" />
              </CustomListItem>
              <CustomListItem
                open={sidebarOpen}
                text={t("sidebar.inProgressProPacks")}
                onClick={proInProgressRedirect}
                currentPath={currentPath}>
                <AutorenewIcon />
              </CustomListItem>
              <CustomListItem
                open={sidebarOpen}
                text={t("sidebar.proCertificates")}
                onClick={proCertificatesRedirect}
                currentPath={currentPath}>
                <WorkspacePremiumIcon />
              </CustomListItem>
            </List>
            <List className="admin-only list">
              <CustomListItem
                open={sidebarOpen}
                text={t("sidebar.users")}
                href={adminUrls.users}
                currentPath={currentPath}>
                <PeopleIcon />
              </CustomListItem>
              <CustomListItem
                open={sidebarOpen}
                text={t("sidebar.orgs")}
                href={adminUrls.orgs}
                currentPath={currentPath}>
                <BusinessIcon />
              </CustomListItem>
            </List>
            <Divider className="admin-user-only" />
            <List className="admin-user-only list">
              <CustomListItem
                open={sidebarOpen}
                text={t("sidebar.admin")}
                href={adminUrls.home}>
                <SupervisorAccountOutlinedIcon />
              </CustomListItem>
            </List>
          </MuiDrawer>
        )}
        {children}
      </div>
    )
  },
  {
    "$$drawerOpenWidth": "240px",
    "$$drawerClosedWidth": "calc($space$7 + 1px)",
    "display": "flex",
    "position": "relative",
    "overflow": "hidden",
    ".drawer": {
      "@initial": {
        position: "absolute",
      },
      "@m": {
        position: "unset",
      },
      "flexShrink": "0",
      "whiteSpace": "nowrap",
      "boxSizing": "border-box",
      ".header": {
        height: "$space$appBarHeight",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "$1 $1 $1 $1",
      },
      ".product-logo svg": {
        marginLeft: "0.1rem",
      },
      // Once client has rendered the sidebar, allow it to be visible
      "&.open.loaded .MuiDrawer-paper": {
        visibility: "unset",
      },
      "&.open": {
        "width": "$$drawerOpenWidth",
        "overflowX": "hidden",
        ".MuiDrawer-paper": {
          "width": "$$drawerOpenWidth",
          "overflowX": "hidden",
          /**
           * Prevent flash of sidebar that shouldn't be visible on initial load.
           * This is a hack due to the fact that we cannot detect browser width
           * on the server.
           */
          "@initial": {
            visibility: "hidden",
          },
          "@m": {
            visibility: "unset",
          },
        },
        ".product-logo svg": {
          marginLeft: "0.25rem",
        },
      },
      "&.closed": {
        "@initial": {
          display: "none",
        },
        "@m": {
          display: "block",
          width: "$$drawerClosedWidth",
        },
        "overflowX": "hidden",
        ".MuiDrawer-paper": {
          width: "$$drawerClosedWidth",
          overflowX: "hidden",
        },
      },
      ".list": {
        padding: "0",
      },
      ".pro-only,.flex-only,.admin-only,.admin-user-only": {
        display: "none",
      },
      "&.flex": {
        ".flex-only": {
          display: "block",
        },
      },
      "&.pro": {
        ".pro-only": {
          display: "block",
        },
      },
      "&.admin": {
        ".admin-only": {
          display: "block",
        },
      },
      "&.admin-user": {
        ".admin-user-only": {
          display: "block",
        },
      },
    },
    ".app-bar": {
      "position": "fixed",
      "height": "$space$appBarHeight",
      "display": "flex",
      "flexDirection": "row",
      "alignItems": "center",
      "justifyContent": "space-between",
      "zIndex": 1500,
      "backgroundColor": "$aoeuBlue",
      "boxShadow": "none",
      ".toolbar": {
        "height": "$space$appBarHeight",
        "width": "100%",
        "position": "relative",
        "display": "flex",
        "justifyContent": "center",
        ".menu-button,.close-button": {
          position: "absolute",
          top: "0.7rem",
          left: "1.2rem",
          marginRight: 2,
        },
        ".logo-container": {
          "width": "100%",
          "display": "flex",
          "justifyContent": "space-between",
          "alignItems": "center",
          "&.logo-container-with-sidebar": {
            paddingLeft: "calc($$drawerClosedWidth - $2)",
          },
          ".logo": {
            width: "200px",
            height: "$space$appBarHeight",
            minWidth: "200px",
          },
        },
      },
      "@m": {
        "&.open .logo-container": {
          marginLeft: "calc($$drawerOpenWidth - $6)",
        },
      },
    },
    ".hidden": { display: "none" },
  },
)

export const getShouldShowSidebar = (
  currentPath: string,
  authenticated: boolean,
): boolean =>
  !(
    BASE_NO_SIDEBAR_PATHS.has(currentPath) ||
    (!authenticated && MARKETPLACE_PATTERN.test(currentPath))
  )

const CustomListItem = ({
  children,
  className,
  text,
  currentPath = "",
  selectedPattern,
  selected,
  open,
  component = NextWrappedLink,
  href,
  target,
  onClick,
}: {
  children: ReactNode
  className?: string
  text: string
  currentPath?: string
  selectedPattern?: RegExp
  selected?: boolean
  open: boolean
  component?: ElementType
} & (
  | {
      href: string
      target?: "_blank"
      onClick?: never
    }
  | {
      href?: never
      target?: never
      onClick: () => void
    }
)) => {
  if (selected) {
    /* nothing to do*/
  } else if (selectedPattern) {
    selected = selectedPattern.test(currentPath)
  } else if (href) {
    selected = rstrip(currentPath, "/").startsWith(rstrip(href, "/"))
  } else {
    selected = false
  }

  return (
    <ListItemButton
      className={className}
      title={text}
      selected={selected}
      component={component}
      decorated={false}
      outlined={false}
      href={href}
      target={target}
      onClick={onClick}
      sx={{
        minHeight: 48,
        justifyContent: open ? "initial" : "center",
        padding: "0 $2 0 $2",
      }}>
      <ListItemIcon
        sx={{
          width: 24,
          display: "flex",
          justifyContent: open ? "initial" : "center",
        }}>
        {children}
      </ListItemIcon>
      <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
    </ListItemButton>
  )
}
